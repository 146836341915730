<!--
 * @Descripttion:
 * @version:
 * @Author: weixin
 * @Date: 2019-12-25 20:07:18
 * @LastEditors: 张天鹏
 * @LastEditTime: 2020-06-03 15:51:02
 -->

 <template>
  <div class="mainbody">
    <md-tabs>
      <div class="type-in">
        <md-field class="productInfo firstInfo">
          <div class="productContent contentTitle">{{riskconList.prodName }}</div>
          <div class="productContent">
            <span class="title">订单号</span>
            <span class="content">{{riskconList.channelOrderCode}}</span>
          </div>
          <div class="productContent">
            <span class="title">保单号</span>
            <span class="content">{{riskconList.compPlcNo}}</span>
          </div>
          <div class="productContent">
            <span class="title">投保单号</span>
            <span class="content">{{riskconList.applyNo?riskconList.applyNo:""}}</span>
          </div>
          <div class="productContent">
            <span class="title">保费</span>
            <span class="content">{{riskconList.premium}}元</span>
          </div>
          <div class="productContent">
            <span class="title">保障时间</span>
            <span class="content">{{riskconList.startTime}}至{{riskconList.endTime}}</span>
          </div>
          <div class="sxImage" v-if="riskconList.orderStatus==='6'">
            <img src="../../assets/xsqd/ic_sx_slices.png" />
          </div>
        </md-field>
        <md-field class="productInfo">
          <div class="productContent contentTitle">投保人</div>
          <div class="productContent">
            <span class="title">姓名</span>
            <span class="content">{{TB.name}}</span>
          </div>
          <div class="productContent">
            <span class="title">证件类型</span>
            <span class="content">{{TB.cardType ? TB.cardType : ''}}</span>
          </div>
          <div class="productContent">
            <span class="title">证件号码</span>
            <span class="content">{{TB.cardNo}}</span>
          </div>
          <div class="productContent">
            <span class="title">手机号</span>
            <span class="content">{{TB.mobile}}</span>
          </div>
          <div class="productContent">
            <span class="title">邮箱</span>
            <span class="content">{{TB.email}}</span>
          </div>
        </md-field>
        <md-field class="productInfo">
          <div class="productContent contentTitle">
            <span class="spanTitle">被保人</span>
            <span class="content viewMore" @click="channelCoopCall(3)" v-if="BB.length>1">
              查看更多
              <img src="../../assets/xsqd/ic_more.png" />
            </span>
          </div>
          <div class="productContent">
            <span class="title">与投保人关系</span>
            <span class="content">{{BB.relattoisd ? BB.relattoisd : ''}}</span>
          </div>
          <div class="productContent">
            <span class="title">姓名</span>
            <span class="content">{{BB.name}}</span>
          </div>
          <div class="productContent">
            <span class="title">证件类型</span>
            <span class="content">{{BB.cardType ? BB.cardType : ''}}</span>
          </div>
          <div class="productContent">
            <span class="title">证件号码</span>
            <span class="content">{{BB.cardNo}}</span>
          </div>
          <div class="productContent">
            <span class="title">手机号</span>
            <span class="content">{{BB.mobile}}</span>
          </div>
          <div class="productContent">
            <span class="title">邮箱</span>
            <span class="content">{{BB.email}}</span>
          </div>
        </md-field>
        <md-field class="productInfo" v-if="SY.length>0">
          <div class="productContent contentTitle">
            <span class="spanTitle">受益人</span>
            <span class="content viewMore" @click="channelCoopCall(3)" v-if="SY.length>1">
              查看更多
              <img src="../../assets/xsqd/ic_more.png" />
            </span>
          </div>
          <div class="productContent">
            <span class="title">与投保人关系</span>
            <span class="content">{{SY.relattoisd ? SY.relattoisd : ''}}</span>
          </div>
          <div class="productContent">
            <span class="title">姓名</span>
            <span class="content">{{SY.name}}</span>
          </div>
          <div class="productContent">
            <span class="title">证件类型</span>
            <span class="content">{{SY.cardType ? SY.cardType : ''}}</span>
          </div>
          <div class="productContent">
            <span class="title">证件号码</span>
            <span class="content">{{SY.cardNo}}</span>
          </div>
          <div class="productContent">
            <span class="title">手机号</span>
            <span class="content">{{SY.mobile}}</span>
          </div>
          <div class="productContent">
            <span class="title">邮箱</span>
            <span class="content">{{SY.email}}</span>
          </div>
          <div class="productContent">
            <span class="title">受益人顺序</span>
            <span class="content">{{SY.bnfSerial}}</span>
          </div>
          <div class="productContent">
            <span class="title">受益人比例</span>
            <span class="content">{{SY.bnfPercent}}</span>
          </div>
        </md-field>
        <md-field class="productInfo bottomInfo">
          <div>
            <div
              class="bottomLIst"
              v-for="(item,index) in bottomList"
              :key="index"
              @click="channelCoopCall(item.id,riskconList.invoiceStatus)"
            >
              <div class="listIcon">
                <img :src="item.img" />
              </div>
              <div class="listText">{{item.text}}</div>
              <div>
                <img src="../../assets/xsqd/ic_enter.png" class="enterIcon" />
              </div>
            </div>
          </div>
        </md-field>
      </div>
    </md-tabs>
    <md-dialog
      title="发票申请"
      :closable="false"
      v-model="basicDialog.open"
      :btns="basicDialog.btns"
    >发票已申请，请在发票申请服务中查看</md-dialog>
  </div>
</template>

<script>
import { Dialog,Toast } from "mand-mobile";
import { getCoopOrderDetail, channelCoopCall } from "@/api/xsqd/index";
import { getStorage } from "@/lib/util";
export default {
  data() {
    return {
      /* 保单详情 */
      policyList: {},
      riskconList: {},
      TB: {},
      BB: {},
      SY: {},
      basicDialog: {
        open: false,
        btns: [
          {
            text: "取消",
            handler: this.onBasicCancel
          },
          {
            text: "继续前往",
            handler: this.onBasicConfirm
          }
        ]
      },
      channelOrderCode: this.$route.query.channelOrderCode,
      appfname: "",
      mount: 0,
      bottomList: []
    };
  },
  created() {
    this.policydetail();
  },
  methods: {
    policydetail() {
      getCoopOrderDetail({ channelOrderCode: this.channelOrderCode }).then(
        res => {
          this.bottomList = [];
          this.riskconList = res.data.data.compPlcInfo;
          if (this.riskconList.orderStatus === "6") {
            this.bottomList.push(
              {
                id: 1,
                img: require("../../assets/xsqd/ic_fpsqsmall.png"),
                text: "发票申请"
              },
              {
                id: 2,
                img: require("../../assets/xsqd/ic_dzbd.png"),
                text: "电子保单"
              },
              {
                id: 3,
                img: require("../../assets/xsqd/ic_dd.png"),
                text: "订单详情"
              }
            );
          } else if (
            this.riskconList.orderStatus === "1" ||
            this.riskconList.orderStatus === "3" ||
            this.riskconList.orderStatus === "4"
          ) {
            this.bottomList.push(
              {
                id: 3,
                img: require("../../assets/xsqd/ic_dd.png"),
                text: "订单详情"
              },
              {
                id: 4,
                img: require("../../assets/xsqd/ic_zc.png"),
                text: "暂存修改"
              }
            );
          } else {
            this.bottomList.push({
              id: 3,
              img: require("../../assets/xsqd/ic_dd.png"),
              text: "订单详情"
            });
          }
          //  this.mount=res.data.data.premium
          res.data.data.personList.forEach(e => {
            if (e.personType === 1) {
              this.TB = e;
            }
            if (e.personType === 2) {
              this.BB = e;
            }
            if (e.personType === 3) {
              this.SY = e;
            }
          });
          //  this.TB = res.data.data.tbr;
          //  this.BB = res.data.data.bbr;
          //  this.SY = res.data.data.syr;
        }
      );
    },
    channelCoopCall(id,val) {
      if (id === 1) {
        if(val){
          this.basicDialog.open = true;
        }else{
          this.getUrl(1,'fp');
        }  
      } else if (id === 2) {
        this.getUrl(3);
      } else if (id === 3) {
        this.getUrl(5);
      } else {
        this.getUrl(7);
      }
    },
    getUrl(val,mg) {
      let bizType;
      if(mg==='fp'){
        bizType=2
      }else{
        bizType=1
      }
      let params = {
        bizType: bizType,
        data: {
          orderNo: this.riskconList.channelOrderCode,
          invoiceNo: this.riskconList.invoiceNo
            ? this.riskconList.invoiceNo
            : "",
          correctNo: this.riskconList.correctNo
        },
        channel: this.riskconList.baseid,
        operType: val
      };
      Toast.loading('跳转中...')
      channelCoopCall(params).then(res => {
        Toast.hide()
        if (res.data.code === 200) {
          if(res.data.data){
               window.location.href=res.data.data
           }
        }
      }).catch(err=>{
        Toast.hide()
      });
    },
    onBasicCancel() {
      this.basicDialog.open = false;
    },
    onBasicConfirm() {
      this.basicDialog.open = false;
      this.$router.push('orderStatus?pageType=3')
    }
  }
};
</script>

 <style lang="stylus" scoped>
 .mainbody {
   background-color: #F7F6FB;
 }

 /deep/ .require {
   .md-field-item-title::after {
     content: '*';
     color: red;
   }
 }

 /deep/ .tjmes {
   .md-field-item-title {
     padding-left: 0;
     font-weight: bold;
   }
 }

 /deep/ .additionalrisks {
   .md-field-item-right {
     img {
       width: 4vw;
       height: 4vw;
     }
   }
 }

 .extrafold {
   display: flex;
   height: 8vh;
   // border-top  10px solid #F8F8FA
   border-bottom: 10px solid #F8F8FA;
   justify-content: space-between;
   align-items: center;

   p {
     font-size: 40px;
     font-family: 'PingFang-SC';
     font-weight: bold;
     color: black;
   }

   img {
     width: 3vw;
     height: 4vw;
   }
 }

 .extrat {
   border-top: 20px solid #F8F8FA;
 }

 .delextra {
   display: flex;
   justify-content: center;
   color: #FE5257;
   cursor: pointer;
 }

 .entry_btn {
   background-color: #228BCF;
   height: 6vh;
   border-radius: 8px;
   color: white;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 2vh;
   margin-bottom: 2vh;
 }

 .productInfo {
   margin: 0.4rem 0.4rem;
   padding: 0.3rem;
   // border-radius: 0.3rem;
 }

 .productContent {
   line-height: 0.8rem;
 }

 .contentTitle, .contentTitle>.spanTitle {
   font-size: 0.43rem;
   font-family: PingFang-SC-Bold;
   font-weight: bold;
   color: #373737;
   margin-bottom: 0.15rem;
 }
.contentTitle>.spanTitle{
  width:30%;
  display: inline-block;
  margin :0;
}

 .contentTitle>.viewMore {
   font-size: 0.32rem;
   font-family: PingFang-SC-Regular;
   font-weight: 400;
   color: #FF7326;

   img {
     width: 0.29rem;
     height: 0.29rem;
     display: inline-block;
     vertical-align: middle;
     margin-bottom: 0.06rem;
   }
 }

 .productContent>span {
   font-size: 0.4rem;
   font-family: PingFang-SC-Regular;
   font-weight: 400;
   color: #555555;
 }

 .title {
   display: inline-block;
   width: 30%;
 }

 .content {
   display: inline-block;
   width: 70%;
   text-align: right;
 }

 .bottomInfo {
   padding: 0 0.3rem;
 }

 .bottomLIst {
   height: 1.47rem;
   display: flex;
   align-items: center;
   position: relative;
   border-bottom: 1px solid #EEEEEE;

   .listIcon {
     width: 0.61rem;
     height: 0.61rem;

     img {
       max-width: 100%;
       max-height: 100%;
     }
   }

   .listText {
     margin-left: 0.21rem;
     width: 7.66rem;
     font-family: PingFang-SC-Medium;
     font-weight: 500;
     color: #333333;
     font-size: 0.4rem;
   }

   .enterIcon {
     width: 0.45rem;
     vertical-align: middle;
     height: 0.45rem;
   }
 }

 .firstInfo {
   position: relative;
 }

 .sxImage {
   position: absolute;
   top: 0;
   right: 0;
   width: 1.2rem;
   height: 1.2rem;

   img {
     max-width: 100%;
     max-height: 100%;
   }
 }

 .md-dialog /deep/ .md-dialog-content {
   width: 7.59rem;

   .md-dialog-body {
     text-align: center;
     padding: 0.71rem 0.52rem 0.67rem 0.52rem;
     font-size: 0.32rem;
     font-family: PingFang-SC-Regular;
     font-weight: 400;
     color: #555555;

     .md-dialog-title {
       font-family: PingFang-SC-Medium;
       font-size: 0.45rem;
       font-weight: 500;
       color: #333333;
       margin-bottom: 0.45rem;
     }
   }

   .md-dialog-actions {
     .md-dialog-btn {
       font-size: 0.4rem;
       font-family: PingFang-SC-Medium;
       font-weight: 500;
     }

     .md-dialog-btn:last-child {
       color: #FF7326;
     }
   }
 }
</style>
